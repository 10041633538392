<template>
  <v-container>
    <v-col class="text-center">
      <v-snackbar
        v-model="snackbar.show"
        timeout="10000"
        top
        elevation="24"
        :color="snackbar.type"
      >
        <div class="text-center">{{ snackbar.text }}</div>
        <template v-slot:action="{ attrs }">
          <v-icon
            text
            dark
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            close
          </v-icon>
        </template>
      </v-snackbar>
    </v-col>

    <v-row>
      <v-col>
        <h1 class="secondary--text">Extratos de Comissão</h1>
      </v-col>
    </v-row>

    <v-form ref="formUpload" lazy-validation @submit.prevent="upload()">
      <v-row class="mt-7 align-center justify-space-between">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="carrier"
            :items="carriers"
            clearable
            item-text="name"
            item-value="folder"
            :return-object="false"
            hide-selected
            :rules="[rule.required]"
            :loading="!carriers.length"
            @input="carrier ? (carrier = carrier) : (carrier = '')"
            label="Selecione"
            persistent-hint
            outlined
            class="mt-2"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Arquivo</label>
          <v-file-input
            v-model="file"
            :rules="[rule.required]"
            class="mt-2"
            placeholder="Arquivo de Extrato de Comissão"
            prepend-icon="fas fa-file"
            chips
            outlined
            truncate-length="15"
          ></v-file-input>
        </v-col>

        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="2"
          lg="2"
          class="justify-end align-end"
        >
          <v-btn
            class="float-right white--text"
            color="textPrimary"
            block
            min-height="47"
            :disabled="snackbar.show || send"
            @keydown.enter="upload()"
            @click="upload()"
          >
            Enviar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
        <v-data-table
          :headers="headers"
          :items="extracts"
          hide-default-footer
          :loading="loading"
          :loading-text="message"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatter.formatDateTimeZoneWithHours(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatter.formatDateTimeZoneWithHours(item.updatedAt) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ formatter.formatComissionExtractStatus(item.status) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-footer>
          <v-row class="d-flex pt-6 justify-start">
            <v-btn
              x-large
              class="float-right white--text"
              color="textPrimary"
              width="250px"
              @click="onClickBack()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>

      <v-col cols="6">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="getExtracts()"
            >
              Atualizar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { AuthenticationService } from '@/services/authentication-service';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';

import FileDataService from '@/services-http/financial/FileDataService';

export default {
  name: 'CommissionExtracts',
  data() {
    return {
      headers: [
        {
          text: 'Arquivo',
          value: 'name',
          align: 'center',
        },
        {
          text: 'Tipo',
          value: 'fileType',
          align: 'center',
        },
        {
          text: 'Extrato',
          value: 'carrierName',
          align: 'center',
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
        },
        {
          text: 'Criado',
          value: 'createdAt',
          align: 'center',
        },
        {
          text: 'Atualizado',
          value: 'updatedAt',
          align: 'center',
        },
      ],
      carrier: '',
      loading: false,
      send: false,
      message: 'Carregando Últimos Extratos de Comissão...',
      file: null,
      extracts: [],
      carriers: [
        { name: 'Default', folder: 'default' },
        { name: 'Amil', folder: 'amil' },
        { name: 'GNDI', folder: 'gndi' },
        { name: 'Bradesco', folder: 'bradesco' },
        { name: 'MetLife Vida', folder: 'metlife_vida' },
        { name: 'MetLife Odonto', folder: 'metlife_odonto' },
        { name: 'SulAmérica', folder: 'sulamerica' },
        { name: 'Porto Seguro', folder: 'portoseguro' },
        { name: 'Allcare', folder: 'allcare' },
        { name: 'Unimed Rio', folder: 'unimed_rio' },
        { name: 'Prudential', folder: 'prudential' },
        { name: 'CNU', folder: 'cnu' },
        { name: 'Seguros Unimed', folder: 'seguros_unimed' },
        { name: 'Odontoprev', folder: 'odontoprev' },
        { name: 'Bradesco Dental', folder: 'bradesco_dental' },
        { name: 'Sompo', folder: 'sompo' },
        { name: 'Porto Seguro Gerais', folder: 'portoseguro_gerais' },
        { name: 'Bradesco Vida Previdência', folder: 'bradesco_vida_previdencia' },
        { name: 'AXA', folder: 'axa' },
        { name: 'Unimed Odonto', folder: 'unimed_odonto' },
      ],
      snackbar: {
        show: false,
        type: '',
        text: '',
      },
    };
  },
  methods: {
    upload() {
      this.customizeSnackbarMessage('warning', 'Enviando Arquivo!');
      this.send = true;

      if (!this.$refs.formUpload.validate()) {
        return;
      }

      const dataForm = new FormData();
      dataForm.append('file', this.file);

      this.fileDataService.Upload(this.carrier, dataForm).then(() => {
        this.file = null;
        this.carrier = null;
        this.customizeSnackbarMessage('success', 'Arquivo Enviado com Sucesso!');
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Problema no Envio do Arquivo!');
      });

      this.send = false;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    async getExtracts() {
      this.loading = true;
      this.extracts = [];

      this.fileDataService.FetchLatest().then((response) => {
        if (response && response.data.length > 0) {
          this.extracts = response.data;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;

        this.customizeSnackbarMessage('error', 'Problema na Busca dos Últimos 50 Extratos!');
      });
    },
    onClickBack() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
  },
  async created() {
    this.rule = new Rules();
    this.formatter = new Formatters();

    if (this.$route.query.token) {
      AuthenticationService.setAuthenticationToken(this.$route.query.token);
    }

    this.fileDataService = new FileDataService();

    this.getExtracts();
  },
};
</script>
