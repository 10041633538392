/* eslint-disable */
import HttpService from '../HttpService'

export default class FileDataService {
  constructor() {
    this._httpService = new HttpService('/financial/file-data');
  }

  async Upload(folder, formData) {
    return await this._httpService.post(`?folder=${folder}`, formData);
  }

  async FetchLatest() {
    return await this._httpService.get(`fetch-latest`);
  }
}
